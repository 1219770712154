import React, { useEffect, useState } from "react";
import Layout from "../layouts/index.jsx";
import Header from "../layouts/header";
import Footer from "../layouts/footer";
import PageTitleContainer from "../containers/global/page-title/index.jsx";
import ScrollToTop from "../components/scroll-to-top";
import SEO from "../components/seo";
import data from "../data2/sss.json";
import Pagination from "rc-pagination";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import ReactHtmlParser from "react-html-parser";

const SSSPage = ({ sss }) => {
    const [collection, setCollection] = useState([]);
    const [resultLast, setResultLast] = useState([]);
    const [name, setName] = useState([]);

    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        document.title = "DR.MÜJDEGÜL KARACA | SSS";
        setCollection(sss);
        setResultLast(sss);
        let arr = [];
        let seen = new Set(),
            result = sss.filter(({ name }) => seen.add(name)),
            name = result.map((key) => {
                const obj = {
                    name: key.name,
                    img: key.img,
                    answer: key.answer,
                    sssNo: key.sssNo,
                    video: key.video,
                };
                arr.push(obj);
            });

        setName(arr);
    }, []);

    console.log(resultLast);
    return (
        <React.Fragment>
            <Layout>
                <SEO title="Dr. Müjdegül Zayıfoğlu Karaca" />
                <div className="wrapper">
                    <Header />
                    <div className="main-content site-wrapper-reveal">
                        <PageTitleContainer
                            image="img/mujdegulkaraca_sss.jpg"
                            subTitle="Dr. Müjdegül Zayıfoğlu Karaca"
                            title="Müjdegül'e Sorduk"
                        />
                        <div className="container mt-5 d-flex justify-content-center">
                            <div className="col-10">
                                <div
                                    className="accordion"
                                    id="accordionExample"
                                >
                                    {resultLast.map((key, index) => (
                                        <div
                                            className="accordion-item"
                                            key={index}
                                        >
                                            <h2
                                                className="accordion-header"
                                                id={`heading${index}`}
                                            >
                                                <button
                                                    className={`accordion-button ${
                                                        index === 0
                                                            ? "show"
                                                            : ""
                                                    }`}
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target={`#collapse${index}`}
                                                    aria-expanded={
                                                        index === 0
                                                            ? "true"
                                                            : "false"
                                                    }
                                                    aria-controls={`collapse${index}`}
                                                >
                                                    {key.name}
                                                </button>
                                            </h2>
                                            <div
                                                id={`collapse${index}`}
                                                className={`accordion-collapse collapse ${
                                                    index === 0 ? "show" : ""
                                                }`}
                                                aria-labelledby={`heading${index}`}
                                                data-bs-parent="#accordionExample"
                                            >
                                                <div className="accordion-body d-flex justify-content-center">
                                                    {key.video && (
                                                        <video
                                                            width={240}
                                                            controls
                                                            poster={`https://api.mujdegulkaraca.com.tr/uploads/${key.img}`}
                                                        >
                                                            <source
                                                                src={`https://api.mujdegulkaraca.com.tr/uploads/${key.video}`}
                                                                type="video/mp4"
                                                            />
                                                            Your browser does
                                                            not support the
                                                            video tag.
                                                        </video>
                                                    )}
                                                    {key.answer && (
                                                        <div>
                                                            {ReactHtmlParser(
                                                                key.answer
                                                            )}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                    <ScrollToTop />
                </div>
            </Layout>
        </React.Fragment>
    );
};

SSSPage.propTypes = {
    sss: PropTypes.array,
};

const mapStateToProps = (state) => {
    return {
        sss: state.sssData.sss,
    };
};

export default connect(mapStateToProps)(SSSPage);
