import React,{useEffect} from "react";
import Layout from "../layouts/index.jsx";
import Header from "../layouts/header";
import PageTitleContainer from "../containers/global/page-title/index.jsx";
import Footer from "../layouts/footer";
import ScrollToTop from "../components/scroll-to-top";
import SEO from "../components/seo";

const OnlineExamination = () => {

    useEffect(() => {
        const metaDescriptionTag = document.querySelector(
            'meta[name="description"]'
          );
          if (metaDescriptionTag) {
            metaDescriptionTag.setAttribute(
                "content",
                "Kadın doğum uzmanı ile online muayene hizmeti, hastaların zaman kaybetmeden doktorlarına ulaşmalarını sağlar."
    
              );
            
          } else {
            const newMetaTag = document.createElement("meta");
            newMetaTag.name = "description";
            newMetaTag.content = `Kadın doğum uzmanı ile online muayene hizmeti, hastaların zaman kaybetmeden doktorlarına ulaşmalarını sağlar.
    `;
            document.head.appendChild(newMetaTag);
          }
    }, []);
   
    return (
        <React.Fragment>
            <Layout>
                <SEO title="Kadın Doğum Uzmanı Online Muayene - Ankara
            

" canonical="https://www.drmujdegul.com/online-muayene" />
                <div className="wrapper">
                    <Header />
                    <div className="main-content site-wrapper-reveal">
                        <PageTitleContainer
                            image="img/mujdegul_hakkimda.jpg"
                            subTitle="Dr. Müjdegül Zayıfoğlu Karaca"
                            title="Online Muayene"
                        />
                        <div className="about-area">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div
                                            className="office-center-content"
                                            data-aos="fade-up"
                                            data-aos-duration="1200"
                                        >
                                            <h1 className="title">
                                                Kadın Doğum Uzmanı Online
                                                Muayene
                                            </h1>

                                            <p>
                                                <strong>
                                                    Kadın doğum uzmanı
                                                </strong>{" "}
                                                ile online muayene hizmeti,
                                                hastaların zaman kaybetmeden
                                                doktorlarına ulaşmalarını
                                                sağlar. Başlıca aşağıdaki
                                                hizmetler sunulur:
                                            </p>

                                            <p>
                                                <li>
                                                    Gebelik takibi ve
                                                    danışmanlığı{" "}
                                                </li>
                                                <li>
                                                    Doğum öncesi ve sonrası
                                                    bakım önerileri
                                                </li>
                                                <li>
                                                    Adet düzensizlikleri,
                                                    menopoz gibi kadın sağlığı
                                                    konularında danışmanlık
                                                </li>
                                                <li>
                                                    Cinsel sağlık sorunları
                                                    hakkında bilgi ve tedavi
                                                    planı
                                                </li>
                                                <li>
                                                    Tahlil sonuçlarının
                                                    değerlendirilmesi
                                                </li>
                                            </p>
                                            <p>
                                                Bu tür hizmetler, fiziksel
                                                muayene gerektirmeyen durumlar
                                                için oldukça uygun olup,
                                                gizlilik ve kolaylık sağlar.
                                            </p>

                                            <p className="text-center">
                                                <a href="https://api.whatsapp.com/send/?phone=905344250343">
                                                    <button
                                                        style={{
                                                            backgroundColor:
                                                                "#25D366",
                                                            color: "white",
                                                            border: "none",
                                                            borderRadius: "5px",
                                                        }}
                                                    >
                                                        Hızlı Randevu Alın
                                                    </button>
                                                </a>
                                            </p>

                                            <h4 className="title">Bilgiler:</h4>
                                            <div
                                                className="service-list"
                                                data-aos="fade-up"
                                                data-aos-duration="1200"
                                            >
                                                <p>
                                                    <li>
                                                        Online muayene yaklaşık
                                                        15-20 dakika
                                                        sürmektedir.
                                                    </li>
                                                    <li>
                                                        Online muayene ücreti
                                                        randevu alındıktan sonra
                                                        size bildirilen banka
                                                        hesabına iletilmelidir.
                                                    </li>
                                                    <li>
                                                        Online muayene ücreti
                                                        1.750 ₺ olarak
                                                        belirlenmiştir. (Yurt
                                                        dışı görüşmeler için
                                                        ücret 75$)
                                                    </li>
                                                    <li>
                                                        <strong>
                                                            {" "}
                                                            Online muayene, yüz
                                                            yüze değil dijital
                                                            ortamda görüntülü
                                                            olarak
                                                            gerçekleşmektedir.
                                                        </strong>
                                                    </li>
                                                    <li>
                                                        <strong>
                                                            Online muayenenin
                                                            ardından yüz yüze
                                                            muayene gerekli
                                                            görülüp ilgili hasta
                                                            yüz yüze muayene
                                                            olmak isterse online
                                                            muayene fiyat farkı
                                                            yüz yüze muayeneden
                                                            düşürülecektir. (Yüz
                                                            yüze muayene ücreti
                                                            3.000 ₺)
                                                        </strong>
                                                    </li>
                                                </p>
                                            </div>
                                        </div>
                                        <div className="d-flex w-50 justify-content-center flex-row ms-auto me-auto">
                                            <img
                                                href="/img/onlineMuayene/DSCF0873.jpg"
                                                style={{ maxHeight: "500px" }}
                                                className="mx-3"
                                                src="/img/onlineMuayene/DSCF0873.jpg"
                                            ></img>
                                            <img
                                                href="/img/onlineMuayene/DSCF0883.jpg"
                                                style={{ maxHeight: "500px" }}
                                                className="mx-3"
                                                src="/img/onlineMuayene/DSCF0883.jpg"
                                            ></img>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                    <ScrollToTop />
                </div>
            </Layout>
        </React.Fragment>
    );
};

export default OnlineExamination;
