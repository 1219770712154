import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import HomePage from "./pages/index";
import "./assets/css/bootstrap.min.css";
import "./assets/scss/style.scss";
import "./assets/css/icofont.css";
import "swiper/components/navigation/navigation.scss";
import "swiper/swiper.scss";
import "./assets/css/animate.css";
import "lightgallery.js/dist/css/lightgallery.css";
import "swiper/components/pagination/pagination.scss";
import AboutPage from "./pages/about";
import SSSPage from "./pages/sss";
import BlogDetailsWrap from "./components/blog-details/index";
import BlogDetailsWrapTwo from "./components/blog-details/vajinusmus";
import ServicePage from "./pages/service";
import ScrollToTop from "./components/scrolltotop";
import ServiceDetails from "./templates/service-details";
import BlogPage from "./pages/blog";
import BlogDetailsPage from "./templates/blog-details";
import BasinDetails from "./templates/basin-details";
import BlogCategory from "./templates/blog-category";
import BlogTag from "./templates/blog-tag";
import BlogDate from "./templates/blog-date";
import BlogAuthor from "./templates/blog-author";
import NotFound from "./pages/NotFound";
import ContactPage from "./pages/contact";
import NavScrollTop from "./components/nav-scroll-top";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { multilanguage, loadLanguages } from "redux-multilanguage";
import Vajinusmus from "./pages/vajinusmus";
import VajinusmusDetay from "./components/blog-details/vajinusmus";
import OnlineExamination from "./pages/onlineExamination";

const App = (props) => {
    props.dispatch(
        loadLanguages({
            languages: {
                en: require("./translation/english.json"),
                tr: require("./translation/turkish.json"),
            },
        })
    );
    useEffect(() => {
        AOS.init({
            offset: 80,
            duration: 1000,
            once: true,
            easing: "ease",
        });
        AOS.refresh();
    }, []);
    return (
        <Router>
            <ScrollToTop>
                {" "}
                <NavScrollTop>
                    <Switch>
                        <Route
                            path={`${process.env.PUBLIC_URL + "/"}`}
                            exact
                            component={HomePage}
                        />

                        <Route
                            path={`${process.env.PUBLIC_URL + "/hakkimda"}`}
                            component={AboutPage}
                        />
                        <Route
                            path={`${process.env.PUBLIC_URL + "/vajinusmus"}`}
                            component={Vajinusmus}
                        />
                        <Route
                            path={`${
                                process.env.PUBLIC_URL +
                                "/sikca_sorulan_sorular"
                            }`}
                            component={SSSPage}
                        />
                        <Route
                            path={`${process.env.PUBLIC_URL + "/basin-medya"}`}
                            component={ServicePage}
                        />
                        <Route
                            path={`${
                                process.env.PUBLIC_URL + "/service-details/:id"
                            }`}
                            component={ServiceDetails}
                        />
                        <Route
                            path={`${process.env.PUBLIC_URL + "/blog"}`}
                            component={BlogPage}
                        />
                        <Route
                            path={`${process.env.PUBLIC_URL + "/online-muayene"}`}
                            component={OnlineExamination}
                        />
                        <Route
                            path={`${
                                process.env.PUBLIC_URL + "/category/:slug"
                            }`}
                            component={BlogCategory}
                        />
                        <Route
                            path={`${process.env.PUBLIC_URL + "/tag/:slug"}`}
                            component={BlogTag}
                        />
                        <Route
                            path={`${process.env.PUBLIC_URL + "/date/:date"}`}
                            component={BlogDate}
                        />
                        <Route
                            path={`${
                                process.env.PUBLIC_URL + "/author/:author"
                            }`}
                            component={BlogAuthor}
                        />
                        <Route
                            path={`${
                                process.env.PUBLIC_URL + "/blog-detay/:name"
                            }`}
                            component={BlogDetailsPage}
                        />
                        <Route
                            path={`${
                                process.env.PUBLIC_URL +
                                "/vajinusmus-detay/:name"
                            }`}
                            component={VajinusmusDetay}
                        />
                        <Route
                            path={`${
                                process.env.PUBLIC_URL + "/basinda_ben/:basinNo"
                            }`}
                            component={BasinDetails}
                        />

                        <Route
                            path={`${process.env.PUBLIC_URL + "/iletisim"}`}
                            component={ContactPage}
                        />
                        <Route
                            path={`${process.env.PUBLIC_URL + "*"}`}
                            component={NotFound}
                        />
                    </Switch>
                </NavScrollTop>
            </ScrollToTop>
        </Router>
    );
};

App.propTypes = {
    dispatch: PropTypes.func,
    strings: PropTypes.object,
    currentLanguageCode: PropTypes.string,
};
export default connect()(multilanguage(App));
