import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";

const MainMenu = () => {
    return (
        <nav>
            <ul className="main-menu">
                <li className="active">
                    <NavLink className="main-menu-link" exact to="/">
                        Anasayfa
                    </NavLink>
                </li>{" "}
                <li>
                    <NavLink
                        className="main-menu-link"
                        to={process.env.PUBLIC_URL + "/hakkimda"}
                    >
                        Hakkımda
                    </NavLink>
                </li>
                <li>
                    <NavLink
                        className="main-menu-link"
                        to={process.env.PUBLIC_URL + "/basin-medya"}
                    >
                        Basın & Medya
                    </NavLink>
                </li>
                <li>
                    <NavLink
                        className="main-menu-link"
                        to={process.env.PUBLIC_URL + "/blog"}
                    >
                        Blog
                    </NavLink>
                </li>
                <li>
                    <NavLink
                        className="main-menu-link"
                        to={process.env.PUBLIC_URL + "/sikca_sorulan_sorular"}
                    >
                        SSS
                    </NavLink>
                </li>
                <li>
                    <NavLink
                        className="main-menu-link"
                        to={process.env.PUBLIC_URL + "/online-muayene"}
                    >
                        Online Muayene
                    </NavLink>
                </li>
                <li>
                    <NavLink
                        className="main-menu-link"
                        to={process.env.PUBLIC_URL + "/iletisim"}
                    >
                        İletişim
                    </NavLink>
                </li>
                <li>
                    <a href="https://api.whatsapp.com/send/?phone=905344250343"><button style={{backgroundColor:"#25D366",color:"white",border:"none",borderRadius:"5px"}}>WHATSAPP</button></a>
                </li>
            </ul>
        </nav>
    );
};

export default MainMenu;
