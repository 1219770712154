import React, { useState, useEffect } from "react";
import SectionTitle from "../../../components/section-title";
import Pagination from "rc-pagination";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import ReactHtmlParser from "react-html-parser";

const cleanAndReplace = (str) => {
    return str
      .toLowerCase()
      .replace(/ğ/g, 'g')
      .replace(/ü/g, 'u')
      .replace(/ş/g, 's')
      .replace(/ı/g, 'i')
      .replace(/ö/g, 'o')
      .replace(/ç/g, 'c')
      .replace(/\s+/g, '-')
;
  };

const ServiceContainer = ({ vajinusmus }) => {
    const [collection, setCollection] = useState([]);
    const [resultLast, setResultLast] = useState([]);
    const [name, setName] = useState([]);

    const [currentPage, setCurrentPage] = useState(1);
    const [count, setCount] = useState(9);

    useEffect(() => {
        document.title = "DR.MÜJDEGÜL KARACA | BLOG";
        setCollection(vajinusmus);
        setResultLast(vajinusmus.slice(0, count));
        let arr = [];
        let seen = new Set(),
            result = vajinusmus.filter(({ name }) => seen.add(name)),
            name = result.map((key) => {
                const obj = {
                    name: key.name,
                    img: key.img,
                    info: key.info,
                    vajNo: key.vajNo,
                    categories: key.categories,
                };
                arr.push(obj);
            });

        setName(arr);
    }, []);

    const updatePage = (pageNumber) => {
        const to = count * pageNumber;
        const from = to - count;
        setCurrentPage(pageNumber);
        setResultLast(collection.slice(from, to));
    };

    return (
        <div className="service-area">
            <div className="container">
                <div className="row d-flex justify-content-center">
                    <div className="col-lg-12">
                        <SectionTitle
                            classOption="text-center"
                            subTitle="Vajinusmus"
                            title="<span>Cinsel Sağlık</span> Vajinusmus"
                        />
                    </div>
                    <div className="row clearfix">
                        {resultLast.map((key, index) => {
                            return (
                                <div
                                    className="col-lg-4 col-md-6 col-sm-12 news-block"
                                    key={index}
                                >
                                    <div
                                        className="news-block-one wow fadeInUp animated animated"
                                        data-wow-delay="00ms"
                                        data-wow-duration="1500ms"
                                    >
                                        <div className="inner-box">
                                            <figure className="image-box d-flex justify-content-center">
                                                <a
                                                     href={`/vajinusmus-detay/${cleanAndReplace(key.name)}`}
                                                >
                                                    <img
                                                        src={`https://api.mujdegulkaraca.com.tr/uploads/${key.img}`}
                                                        alt=""
                                                    />
                                                </a>
                                            </figure>
                                            <div className="lower-content">
                                                <span className="feature">
                                                    {key.categories}
                                                </span>
                                                <h3>
                                                    <a
                                                        href={`/vajinusmus-detay/${cleanAndReplace(key.name)}`}
                                                    >
                                                        {key.name}
                                                    </a>
                                                </h3>
                                                <ul className="post-info clearfix">
                                                    <li>
                                                        <img
                                                            style={{
                                                                height: "3vh",
                                                            }}
                                                            src="/img/blog/mujde.jpg"
                                                            alt=""
                                                        />
                                                        Dr. Müjdegül KARACA
                                                    </li>
                                                    {/* <li>Nisan 18, 2020</li> */}
                                                </ul>
                                                <p>
                                                    {ReactHtmlParser(
                                                        key.info.slice(0, 150)
                                                    )}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                    <div className="pagination-wrapper centred">
                        <Pagination
                            className="col-lg-12 text-center pagination d-flex justify-content-center"
                            pageSize={count}
                            onChange={updatePage}
                            current={currentPage}
                            total={collection.length}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

ServiceContainer.propTypes = {
    vajinusmus: PropTypes.array,
};

const mapStateToProps = (state) => {
    return {
        vajinusmus: state.vajinusmusData.vajinusmus,
    };
};

export default connect(mapStateToProps)(ServiceContainer);
