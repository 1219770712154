import { FETCH_SSS_SUCCESS } from "../actions/sssAction";

const initState = {
    sss: [],
};

const sssReducer = (state = initState, action) => {
    if (action.type === FETCH_SSS_SUCCESS) {
        return {
            ...state,
            sss: action.payload,
        };
    }

    return state;
};

export default sssReducer;
