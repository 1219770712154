import React from "react";
import { changeLanguage } from "redux-multilanguage";
import PropTypes from "prop-types";
import { multilanguage, loadLanguages } from "redux-multilanguage";
import { connect } from "react-redux";

const language = (props) => {
    return (
        <>
            {" "}
            {/* <div className="">
                <div
                    className="language"
                    role="radiogroup"
                    aria-labelledby="language-switcher1"
                >
                    <div className="language__container--left language__container--fr">
                        <input
                            onChange={(e) => {
                                props.dispatch(changeLanguage(e.target.value));
                            }}
                            className="language__control"
                            type="radio"
                            id="language1-1"
                            aria-label="en"
                            name="en"
                            value={"en"}
                            checked={
                                props.currentLanguageCode == "en" ? true : false
                            }
                        />
                        <label
                            className="language__label"
                            htmlFor="language1-1"
                        >
                            EN
                            <span className="hidden"></span>
                        </label>
                    </div>
                    <div className="language__container--right language__container--en">
                        <input
                            onChange={(e) => {
                                props.dispatch(changeLanguage(e.target.value));
                            }}
                            className="language__control"
                            type="radio"
                            id="language1-2"
                            aria-label="tr"
                            name="tr"
                            value={"tr"}
                            checked={
                                props.currentLanguageCode == "tr" ? true : false
                            }
                        />
                        <label
                            className="language__label"
                            htmlFor="language1-2"
                        >
                            TR
                            <span className="hidden"> </span>
                        </label>
                    </div>
                </div>
            </div> */}
        </>
    );
};
language.propTypes = {
    dispatch: PropTypes.func,
    strings: PropTypes.object,
    currentLanguageCode: PropTypes.string,
};

export default connect()(multilanguage(language));
